import createAxios from '@/lib/axios';
import { AxiosInstance, AxiosResponse } from 'axios';

export const SMART_CONSO_QUESTIONNAIRE_ID = 0;

export interface Choice {
  suggestionId: number;
}

export interface Response {
  questionId: number;
  selectedChoices?: Choice[];
  responseValue?: string;
}

export interface QuestionnaireData {
  questionnaireId: number;
  responses: Response[];
}

class PreferencesApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);
  }

  getQuestionnaire = async (userId: string) => {
    let response;

    try {
      response = await this.axios.get<
        undefined,
        AxiosResponse<QuestionnaireData>
      >(
        `/consommateur/v1/customers/${userId}/questionnaires/${SMART_CONSO_QUESTIONNAIRE_ID}/preferences`
      );
    } catch (error: any) {
      throw new Error(error);
    }

    return response?.data || { questionnaireId: 0, responses: [] };
  };

  update = async (userId: string, data: QuestionnaireData) => {
    try {
      await this.axios.post<QuestionnaireData>(
        `/consommateur/v1/customers/${userId}/preferences`,
        data
      );
    } catch (error: any) {
      throw new Error(error);
    }
  };

  delete = (userId: string) => {
    return this.axios.delete<undefined, [string]>(
      `/consommateur/v1/customers/${userId}/questionnaires/${SMART_CONSO_QUESTIONNAIRE_ID}/preferences`
    );
  };
}

export default new PreferencesApi();
