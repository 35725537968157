import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import { Dayjs } from 'dayjs';
import Date from '@/lib/utils/date';
import { FidelityInfo, OperationBalance } from './types';

import { TimeOutException, UnknownErrorException } from './exceptions';

type InfoRequest = {
  firstName: string;
  lastName: string;
  fidelityCardNumber?: string;
  birthdayDate: Dayjs;
};

class FidelityApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);
  }

  getInfo = async (infoRequest: InfoRequest): Promise<FidelityInfo> => {
    const today = Date();
    const header: any = {};

    header['requestDate'] = today.format('YYYYMMDD');
    header['requestTime'] = today.format('HHmmss');
    header['processType'] = '3';
    header['loyaltyCode'] = 'IP';
    header['lang'] = 'PT';
    header['channel'] = 'WEB';

    const response = await this.axios.post(
      '/cartefidelite/v1/loyalty_card/infos',
      {
        identClient: {
          firstName: infoRequest.firstName,
          lastName: infoRequest.lastName,
          numFid: infoRequest.fidelityCardNumber,
          dateN: infoRequest.birthdayDate.format('DD/MM/YYYY')
        },
        header
      }
    );
    const data = response?.data ?? {};
    let balanceBeforeReset;

    if (data.flagRAZ === 'true') {
      const razAmount = parseInt(data.soldeRaz, 10);
      const razAmountSplit: Array<string> = (razAmount / 100)
        .toFixed(2)
        .split('.');
      balanceBeforeReset = {
        amount: {
          currency: '€',
          value: razAmount,
          decimal: razAmountSplit[1],
          integer: razAmountSplit[0]
        },
        calculatedOn: Date(data.dateSoldeRaz),
        resetOn: Date(data.dateFinRaz)
      };
    }
    const balanceAmount = parseInt(data.soldeGlobal, 10);
    const balanceAmountSplit = (balanceAmount / 100).toFixed(2).split('.');

    const globalBalanceAmount = parseInt(data.soldeGlobal, 10);
    const globalBalanceAmountSplit = (balanceAmount / 100)
      .toFixed(2)
      .split('.');

    const onlineUsableBalanceAmount = parseInt(data.soldeDec, 10);
    const onlineUsableBalanceAmountSplit = (onlineUsableBalanceAmount / 100)
      .toFixed(2)
      .split('.');

    const infos: FidelityInfo = {
      type: data.statut,
      pdv: data.numPdv,
      cardNumber: data.numFid,
      operationsBalances: {},
      balance: {
        amount: {
          currency: '€',
          value: balanceAmount,
          decimal: balanceAmountSplit[1],
          integer: balanceAmountSplit[0]
        },
        calculatedOn: Date(data.dateHeure)
      },
      countVisits: parseInt(data.nbVisitesEnCours, 10),
      balanceBeforeReset,
      availableBalance: data.listeSoldeImm?.map((soldeImm: any) => ({
        codeCanalDecagnot: soldeImm.codeCanalDecagnot,
        balance: parseInt(soldeImm.soldeImm, 10)
      })),
      globalBalance: {
        amount: {
          currency: '€',
          value: globalBalanceAmount,
          decimal: globalBalanceAmountSplit[1],
          integer: globalBalanceAmountSplit[0]
        },
        calculatedOn: Date(data.dateHeure)
      },
      onlineUsableBalance: {
        amount: {
          currency: '€',
          value: onlineUsableBalanceAmount,
          decimal: onlineUsableBalanceAmountSplit[1],
          integer: onlineUsableBalanceAmountSplit[0]
        },
        calculatedOn: Date(data.dateHeure)
      },
      pendingBalance: data.listeSoldeAtt?.map((soldeAtt: any) => ({
        codeCanalDecagnot: soldeAtt.codeCanalDecagnot,
        balance: parseInt(soldeAtt.soldeAtt, 10),
        validityStartDate: Date(soldeAtt.dateDebutUtil)
      })),
      deferredBalances: data.listeSoldeDiff?.map((soldeDiff: any) => ({
        codeCanalDecagnot: soldeDiff.codeCanalDecagnot,
        balance: parseInt(soldeDiff.soldeDiff, 10),
        validityStartDate: Date(soldeDiff.dateDebutUtil),
        validityEndDate: Date(soldeDiff.dateFinUtil)
      }))
    };

    data.operationsBalances?.forEach((element: OperationBalance) => {
      if (infos.operationsBalances)
        infos.operationsBalances[element.code] = element;
    });
    return infos;
  };

  async createFidelityCard(userId: string, pdvRef: string) {
    let resultApi = false;

    try {
      const response = await this.axios.put(
        `/gestiondecompte/v1/moncompte/${userId}`,
        {
          veutCarteDemat: true,
          pdvFid: pdvRef
        }
      );

      if (response.status === 204) {
        resultApi = true;
      }
    } catch (error: any) {
      logger.error({
        message: 'Unable to create card',
        error
      });

      const response = error?.response;
      throw response?.status === 504
        ? new TimeOutException()
        : new UnknownErrorException();
    }
    return resultApi;
  }
}

export default new FidelityApi();
