import { ReactComponent as WarningPicto } from '@/assets/images/icons/warning.svg';
import Button from '@/components/atoms/button';
import FormInput from '@/components/atoms/formInput';
import Link from '@/components/atoms/link';
import Modal from '@/components/organisms/modal';
import SecurityLayout from '@/components/organisms/securityLayout';
import FidelityApi from '@/lib/api/fidelity';
import pdvApi from '@/lib/api/pdv';
import cartApi from '@/lib/api/cart';
import PreferencesApi from '@/lib/api/preferences';
import security from '@/lib/api/security';
import {
  DisabledAccountException,
  UnauthorizedException,
  BlockedAccountException
} from '@/lib/api/security/exceptions';
import userInformationApi from '@/lib/api/userInformation';
import useEvent from '@/lib/hooks/useEvent';
import usePdv from '@/lib/hooks/usePdv';
import t from '@/lib/i18n';
import logger from '@/lib/logger/base';
import { setPdvCookie } from '@/lib/utils/pdv';
import useShallowEqualSelector from '@/lib/utils/useShallowEqualSelector';
import { LOGIN } from '@/store/auth/authActions';
import { selectCart } from '@/store/cart/cartSelectors';
import { InitSurveyAction, INIT_SURVEY_STORE } from '@/store/survey/actions';
import { actionCleanSurvey } from '@/store/survey/surveyReducer';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import './style.scss';

type FormType = {
  email: string;
  password: string;
};

type ConnexionProps = {
  pageView?: string;
};

const Connexion = ({ pageView }: ConnexionProps) => {
  const [loading, setLoading] = useState(false);
  const [goBackUrl, setGoBackUrl] = useState('/home');
  const [unauthorizedMessage, setUnauthorizedMessage] = useState(false);
  const [disabledAccountMessage, setDisabledAccountMessage] = useState(false);
  const [blockedAccountMessage, setBlockedAccountMessage] = useState(false);
  const [internalErrorModal, setInternalErrorModal] = useState(false);

  const router = useRouter();
  const event = useEvent();
  const redirectUrl = router?.query['redirect'] as string;

  const dispatch = useDispatch();
  const { subCarts } = useShallowEqualSelector(selectCart);
  const cleanSurvey = actionCleanSurvey(dispatch);

  const { register, errors, handleSubmit, watch } = useForm<FormType>({
    shouldUnregister: false,
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const email = watch('email');
  const password = watch('password');

  const onSubmit = async (formData: FormType) => {
    const onSubmitCallback = async () => {
      const pdv = usePdv();

      setUnauthorizedMessage(false);
      setLoading(true);

      try {
        const data = await security.login(
          formData.email.trim(),
          formData.password.trim()
        );
        const userInfo = await userInformationApi.getInformations(data.id);

        if (subCarts.length) {
          try {
            await cartApi.deleteCart(data.id);
          } catch (error: any) {
            logger.error({
              message: 'Unable to delete the remote cart',
              error
            });
            await cartApi.deleteCart(data.id);
          }
        }

        let numberOfVisits = 0;

        if (userInfo.fidelityCardNumber) {
          try {
            const fidelityInfos = await FidelityApi.getInfo({
              lastName: userInfo.lastName,
              firstName: userInfo.firstName,
              birthdayDate: userInfo.birthdayDate,
              fidelityCardNumber: userInfo.fidelityCardNumber
            });
            numberOfVisits = fidelityInfos.countVisits;
          } catch (e) {
            numberOfVisits = 0;
          }
        }
        dispatch({
          type: LOGIN,
          payload: {
            id: data.id,
            lastName: userInfo.lastName,
            firstName: userInfo.firstName,
            mobilePhone: userInfo.mobilePhone,
            lastVisitedPdv: userInfo.lastVisitedPdv ?? null,
            countVisits: numberOfVisits,
            civility: userInfo.civility,
            email: userInfo.email
          }
        });

        // TODO : Need to implement the retry function
        try {
          const survey = await PreferencesApi.getQuestionnaire(data.id);
          dispatch({
            type: INIT_SURVEY_STORE,
            payload: {
              survey
            }
          } as InitSurveyAction);
        } catch (error: any) {
          logger.error({
            message: 'Unable to retrieve the user preferences',
            error
          });
          cleanSurvey({ withMessage: false });
        }

        event.send('api', {
          name: 'login',
          type: 'success'
        });

        let urlRedirect = redirectUrl || goBackUrl;

        if ((!pdv.ref || pdv.isVirtual) && userInfo.lastVisitedPdv) {
          const lastVisitedPdv = await pdvApi.getPdvInfo(
            userInfo.lastVisitedPdv
          );

          if (lastVisitedPdv) {
            setPdvCookie(lastVisitedPdv);
            urlRedirect = '/home';
          }
        }

        window.location.href = urlRedirect;
      } catch (error: any) {
        event.send('api', {
          name: 'login',
          type: 'error'
        });
        if (error instanceof UnauthorizedException) {
          setUnauthorizedMessage(true);
        } else if (error instanceof DisabledAccountException) {
          setDisabledAccountMessage(true);
        } else if (error instanceof BlockedAccountException) {
          setBlockedAccountMessage(true);
        } else {
          setInternalErrorModal(true);
        }

        setLoading(false);
      }
    };
    onSubmitCallback();
  };

  useEffect(() => {
    if (!document.referrer) {
      return;
    }

    const url = new URL(document.referrer);

    if (
      url.pathname !== '/connection' &&
      url.pathname !== '/my-account/creation/confirmation'
    ) {
      setGoBackUrl(document.referrer);
    }
  }, []);

  return (
    <>
      <SecurityLayout
        goBack={{
          label: t('connexion.goBack.label'),
          url: goBackUrl
        }}
        pageView={pageView}
      >
        <>
          {unauthorizedMessage && (
            <div className="connexion__invalid">
              <WarningPicto width={20} height={20} /> {t('connexion.invalid')}
            </div>
          )}
          {disabledAccountMessage && (
            <div className="connexion__invalid">
              <WarningPicto width={20} height={20} />
              <span
                dangerouslySetInnerHTML={{
                  __html: t('connexion.disabled-account', {
                    '%link%': `<a class="emailLink" href="/account-activation?email=${email}">${t(
                      'connexion.disabled-account.link'
                    )}</a>`
                  })
                }}
              />
            </div>
          )}
          {blockedAccountMessage && (
            <div className="connexion__invalid">
              <WarningPicto width={20} height={20} />
              <div>
                <div className="connexion__invalid__heading">
                  {t('connexion.blocked-account')}
                </div>

                <Link
                  href={`/forgotten-password?email=${email}`}
                  className="connexion__invalid__forgottenPasswordLink"
                >
                  {t('connexion.blocked-account.link')}
                </Link>
              </div>
            </div>
          )}
          <h1 className="connexion__title">{t('connexion.title')}</h1>
          <p className="connexion__info">{t('connexion.subTitle')}</p>
          <form
            method="POST"
            autoComplete="on"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormInput
              className="connexion__input"
              value={email}
              label={t('connexion.form.label.email')}
              ref={register({ required: true })}
              name="email"
              id="email"
              extraAttributes={{
                autoComplete: 'username'
              }}
              errorMessage={
                errors.email ? t(`form.error.${errors.email.type}`) : ''
              }
            />
            <FormInput
              className="connexion__input"
              type="password"
              value={password}
              label={t('connexion.form.label.password')}
              ref={register({ required: true })}
              name="password"
              id="password"
              extraAttributes={{
                autoComplete: 'current-password'
              }}
              errorMessage={
                errors.password ? t(`form.error.${errors.password.type}`) : ''
              }
            />
            <Button
              loading={loading}
              type="submit"
              className="connexion__button--submit"
              label={t('form.button.validate')}
              disabled={!email || !password}
            />
          </form>
          <div className="connexion__footer">
            <Link
              href={`/forgotten-password?email=${email}`}
              className="connexion__link"
            >
              {t('connexion.footer.link.passwordForgotten')}
            </Link>
            <h2 className="connexion__title">
              {t('connexion.footer.createAccountTitle')}
            </h2>
            <Link
              href="/my-account/create-my-account"
              className="connexion__link"
            >
              {t('connexion.footer.link.createAccount')}
            </Link>
          </div>
          <Modal
            className="connexion__internalError"
            open={internalErrorModal}
            onClose={() => {
              setInternalErrorModal(false);
            }}
          >
            <h2>{t('connexion.internalError.title')}</h2>
            <p>{t('connexion.internalError.content')}</p>
          </Modal>
        </>
      </SecurityLayout>
    </>
  );
};

export default Connexion;
